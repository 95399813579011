
.title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-top: 1em;
    font-size: 80px;
    color:white;
    text-transform: uppercase;
  }

  .sub-title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-top: 1em;
    font-size: 40px;
    color:#aaa;
    text-transform: uppercase;
  }

  .album-cover {
    display:flex;
    justify-content: center;
    margin: 2em;
    height: 20em;
  }