.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.image-gallery img {
  width: 33%;
}

.video-gallery {
  display: flex;
  flex-wrap: wrap;
}

.video-gallery video {
  width: 50%;
}

/* Make the image and video gallery responsive */
@media (max-width: 768px) {
  .image-gallery img,
  .video-gallery video {
    width: 100%;
  }
}
