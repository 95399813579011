.container {
  background-color: white;
  margin-top: 8em;
}

.album-description {
  margin-top: 3em;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.section-one {
  padding-top: 2em;
  font-size: 2em;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.section-one-sub {
  margin-top: 5px;
  font-size: 2em;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #aaa;
}

.section-one-detail {
  padding-bottom: 5em;
  margin: 2em 2em 10em 2em;
}
